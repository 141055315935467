module.exports = {
  plugins: [
    ({ addUtilities }) => {
      addUtilities({
        ".title": {
          "&-1": {
            "@apply text-t1": {},
            "@screen md": {
              "@apply text-t1-md": {},
            },
          },
          "&-2": {
            "@apply text-t2": {},
            "@screen md": {
              "@apply text-t2-md": {},
            },
          },
        },
        ".para": {
          "&-1": {
            "@apply text-p1": {},
            "@screen md": {
              "@apply text-p1-md": {},
            },
          },
          "&-2": {
            "@apply text-p2": {},
            "@screen md": {
              "@apply text-p2-md": {},
            },
          },
        },
        ".label": {
          "&-1": {
            "@apply text-l1": {},
            "@screen md": {
              "@apply text-l1-md": {},
            },
          },
          "&-2": {
            "@apply text-l2": {},
            "@screen md": {
              "@apply text-l2-md": {},
            },
          },
          "&-3": {
            "@apply text-l3": {},
            "@screen md": {
              "@apply text-l3-md": {},
            },
          },
          "&-4": {
            "@apply text-l4": {},
            "@screen md": {
              "@apply text-l4-md": {},
            },
          },
        },
        ".button": {
          "&-text-1": {
            "@apply text-button1 md:text-button1-md": {},
          },
          "&-text-2": {
            "@apply text-button2 md:text-button2-md": {},
          },
          "&-text-3": {
            "@apply text-button3 md:text-button3-md": {},
          },
          "&:disabled": { "@apply cursor-default": {} },
        },
        ".appContainer": {
          padding: "0px calc(100% - 95%)",
          margin: "0px auto",
          "max-width": "100%",
        },
        ".no-container": {
          padding: "0px",
          margin: "auto",
        },
      });
    },
  ],
  important: true,
};
